import returnEnvironmentAPIBase from "../util/UtilFunctions";
// const setApiUrlBase=()=>{
//     if (process.env.NODE_ENV==='development'){
//       return 'http://localhost:8000';
//     }
//     else if (process.env.NODE_ENV==='production'){
//       const hostname = window && window.location && window.location.hostname;

//       if (hostname.startsWith('dev') || hostname.startsWith('dev-talent-promo')){
//         return 'https://api-dev.talent.promo';
//       }
//       else if (hostname.startsWith('talent-promo')){
//         return 'https://api.talent.promo';
//       }
//     }
//   }

export const API_URL_BASE = returnEnvironmentAPIBase();
//process.env.REACT_APP_API_BASE;

export const SERVER_ERROR_MESSAGE = "internal server error !";
