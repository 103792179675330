import {
    GET_CREATORS_SUCCESS,
    CREATOR_DELETE_SUCCESS,
    CREATOR_ISACTIVE_CHANGE_SUCCESS,
    CREATOR_UPDATE_SUCCESS,
    CREATOR_TYPE_UPDATE_SUCCESS,
    GET_CREATOR_REQUEST,
    GET_CREATOR_SUCCESS,
} from 'constants/ActionTypes';

const INIT_STATE = {
    creatorsLoader: true,
    creators: [],
    showMessage: '', 
    creatorLoader: false,
    creatorTemplates: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CREATORS_SUCCESS: {
            return {
                ...state,
                creatorsLoader: false,
                creators: action.payload
            }
        }
        case CREATOR_DELETE_SUCCESS: {
            return {
                ...state,
                creatorsLoader: false,
                creators: action.payload
            }
        }
        case CREATOR_ISACTIVE_CHANGE_SUCCESS: {
            let creators = state.creators;
            for(let i=0;i<creators.length;i++) {
                if(creators[i].userId==action.payload.id) {
                    // console.log({...creators[i], ...action.payload});
                    creators[i] = {...creators[i], isActive: action.payload.isActive? action.payload.isActive: 0};
                }
            }
            return {
                ...state,
                recordsLoader: false,
                creators: [...creators],
                loader: false
            }
        }
        case CREATOR_TYPE_UPDATE_SUCCESS: {
            let creators = state.creators;
            for(let i=0;i<creators.length;i++) {
                if(creators[i].userId==action.payload.userId) {
                    creators[i] = {...creators[i], isPublisherCategory: action.payload.isPublisherCategory? action.payload.isPublisherCategory: 0};
                }
            }
            return {
                ...state,
                recordsLoader: false,
                creators: [...creators],
                loader: false
            }
        }
        case CREATOR_UPDATE_SUCCESS: {
            let creators = state.creators;
            let params = action.payload;
            for(let i=0;i<creators.length;i++) {
                if(creators[i].userId==action.payload.userId) {
                    creators[i] = {...creators[i], ...params};
                    console.log(creators[i]);
                }
            }
            return {
                ...state,
                recordsLoader: false,
                creators: [...creators],
                loader: false
            }
        }
        case GET_CREATOR_REQUEST: {
            // Getting creator and template data but using only template data
            return {
                ...state,
                creatorLoader: true,
                creatorTemplates: []
            }
        }
        case GET_CREATOR_SUCCESS: {
            // Getting creator and template data but using only template data
            return {
                ...state,
                creatorLoader: false,
                creatorTemplates: action.payload.templates
            }
        }
        default:
            return state;
    }
}