// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";

//Contact Module const

export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";

export const FETCH_ALL_CONTACT = "fetch_all_contact";
export const FETCH_ALL_CONTACT_SUCCESS = "fetch_all_contact_success";
export const ADD_FAVOURITE = "add_favourite";
export const ON_CONTACT_SELECT = "on_contact_select";
export const ON_ADD_CONTACT = "on_add_contact";
export const ON_CONTACT_CLOSE = "on_contact_close";
export const ON_FILTER_OPTION_SELECT = "on_filter_option_select";
export const ON_SAVE_CONTACT = "on_save_contact";
export const ON_DELETE_CONTACT = "on_delete_contact";
export const ON_DELETE_SELECTED_CONTACT = "on_delete_selected_contact";
export const FILTER_CONTACT = "filter_contact";
export const GET_ALL_CONTACT = "get_all_contact";
export const GET_UNSELECTED_ALL_CONTACT = "get_unselected_all_contact";
export const ON_ALL_CONTACT_SELECT = "on_all_contact_select";
export const UPDATE_SEARCH_USER = "update_search_user";
export const ON_TOGGLE_DRAWER = "on_toggle_drawer";
export const HANDLE_REQUEST_CLOSE = "handle_request_close";
export const HIDE_CONTACT_LOADER = "hide_contact_loader";

//Auth const
export const SIGNUP_USER = "signup_user";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNIN_USER = "signin_user";
export const SIGNIN_PROGRESS = "signin_progress";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";
export const SET_CURRENT_USER = "set_current_user";
export const SET_CURRENT_USER_ERROR = "SET_CURRENT_USER_ERROR";

// Chat Module const

export const FETCH_ALL_CHAT_USER = "fetch_all_chat_user";
export const FETCH_ALL_CHAT_USER_CONVERSATION =
  "fetch_all_chat_user_conversation";
export const FETCH_ALL_CHAT_USER_SUCCESS = "fetch_all_chat_user_success";
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS =
  "fetch_all_chat_user_conversation_success";
export const FILTER_USERS = "filter_users";
export const ON_SELECT_USER = "on_select_user";
export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";
export const USER_INFO_STATE = "user_info_state";
export const SUBMIT_COMMENT = "submit_comment";
export const UPDATE_MESSAGE_VALUE = "update_message_value";
export const UPDATE_SEARCH_CHAT_USER = "update_search_chat_user";

//// Mail Module const

export const ADD_LABEL = "ADD_LABEL";
export const GET_ALL_MAIL = "get_all_mail";
export const FETCH_ALL_MAIL = "fetch_all_mail";
export const FETCH_ALL_MAIL_SUCCESS = "fetch_all_mail_success";
export const GET_IMPORTANT_MAIL = "get_important_mail";
export const GET_NAV_FILTERS = "get_nav_filters";
export const GET_NAV_LABELS = "get_nav_labels";
export const GET_NAV_FOLDER = "get_nav_folder";
export const GET_READ_MAIL = "get_read_mail";
export const GET_STARRED_MAIL = "get_starred_mail";
export const GET_UNIMPORTANT_MAIL = "get_unimportant_mail";
export const GET_UNREAD_MAIL = "get_unread_mail";
export const GET_UNSELECTED_ALL_MAIL = "get_unselected_all_mail";
export const GET_UNSTARRED_MAIL = "get_unstarred_mail";
export const ON_ALL_MAIL_SELECT = "on_all_mail_select";
export const ON_FOLDER_MENU_ITEM_SELECT = "on_folder_menu_item_select";
export const ON_FOLDER_SELECT = "on_folder_select";
export const ON_IMPORTANT_SELECT = "on_important_select";
export const ON_LABEL_MENU_ITEM_SELECT = "on_label_menu_item_select";
export const GET_MARK_AS_START = "get_mark_as_start";
export const ON_LABEL_SELECT = "on_label_select";
export const ON_MAIL_CHECKED = "on_mail_checked";
export const ON_MAIL_SELECT = "on_mail_select";
export const ON_MAIL_SEND = "on_mail_send";
export const ON_OPTION_MENU_ITEM_SELECT = "on_option_menu_item_select";
export const ON_OPTION_MENU_SELECT = "on_option_menu_select";
export const ON_START_SELECT = "on_start_select";
export const SEARCH_MAIL = "search_mail";
export const ON_DELETE_MAIL = "on_delete_mail";
export const SET_CURRENT_MAIL_NULL = "set_current_mail_null";
export const ON_COMPOSE_MAIL = "on_compose_mail";

//// TO-DO Module const

export const ON_SORTEND = "on_sortend";
export const FETCH_ALL_TODO = "fetch_all_todo";
export const FETCH_ALL_TODO_SUCCESS = "fetch_all_todo-success";
export const FETCH_ALL_TODO_CONVERSATION = "fetch_all_todo_conversation";
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS =
  "fetch_all_todo_conversation_success";
export const SELECT_ALL_TODO = "select_all_todo";
export const GET_ALL_TODO = "get_all_todo";
export const GET_UNSELECTED_ALL_TODO = "get_unselected_all_todo";
export const GET_STARRED_TODO = "get_starred_todo";
export const GET_UNSTARRED_TODO = "get_unstarred_todo";
export const GET_IMPORTANT_TODO = "get_important_todo";
export const GET_UNIMPORTANT_TODO = "get_unimportant_todo";
export const ON_LABEL_UPDATE = "on_label_update";
export const ON_TODO_UPDATE = "on_todo_update";
export const ON_DELETE_TODO = "on_delete_todo";
export const SEARCH_TODO = "search_todo";
export const SHOW_TODOS = "show_todos";
export const GET_TODO_CONVERSATION = "get_todo_conversation";
export const ON_TODO_CHECKED = "on_todo_checked";
export const ON_TODO_ADD = "on_todo_add";
export const ON_TODO_SELECT = "on_todo_select";
export const SET_CURRENT_TODO_NULL = "set_current_todo_null";
export const REMOVE_LABEL = "remove_label";
export const UPDATE_SEARCH = "update_search";

// role
export const GET_ALL_ROLE = "get_all_role";
export const GET_ALL_ROLE_SUCCESS = "get_all_role_success";
export const GET_ROLE = "get_role";
export const GET_ROLE_SUCCESS = "get_role_success";
export const UPDATE_ROLE = "update_role";
export const UPDATE_ROLE_SUCCESS = "update_role_success";
export const UPDATE_ROLE_ERROR = "update_role_error";
export const CREATE_ROLE = "create_role";
export const CREATE_ROLE_SUCCESS = "create_role_success";
export const CREATE_ROLE_ERROR = "create_role_error";
export const DELETE_ROLE = "delete_role";
export const DELETE_ROLE_SUCCESS = "delete_role_Success";

// user
export const GET_CURRENT_USER = "get_current_user";
export const GET_CURRENT_USER_SUCCESS = "get_current_user_success";
export const GET_CURRENT_USER_FAILED = "get_current_user_failed";
export const GET_ALL_USER = "get_all_user";
export const GET_ALL_USER_SUCCESS = "get_all_user_success";
export const GET_USER = "get_user";
export const GET_USER_SUCCESS = "get_user_success";
export const UPDATE_USER = "update_role";
export const UPDATE_USER_SUCCESS = "update_user_success";
export const UPDATE_USER_STATUS = "update_user_status";
export const UPDATE_USER_STATUS_SUCCESS = "update_user_status_success";
export const UPDATE_VERIFY_FIELD_SUCCESS = "update_verify_field_success";
export const UPDATE_USER_ERROR = "update_user_error";
export const DELETE_USER = "delete_user";
export const DELETE_USER_SUCCESS = "delete_user_success";
export const CREATE_USER = "create_user";
export const CREATE_USER_SUCCESS = "create_user_success";
export const CREATE_USER_ERROR = "create_user_error";
export const CHECK_EMAIL = "check_email";
export const CHECK_EMAIL_SUCCESS = "check_email_success";
export const CHECK_EMAIL_ERROR = "check_email_ERROR";
export const SHOW_SUCCESS_MESSAGE = "show_success_message";
export const UPDATE_USER_EMAIL_SUCCESS = "update_user_email_success";
export const UPDATE_USER_CREDIT_SUCCESS = "update_user_credit_success";
export const  SHOW_CREDIT_UPDATE = 'SHOW_CREDIT_UPDATE'

// plan
export const GET_ALL_PLAN = "get_all_plan";
export const GET_ALL_PLAN_SUCCESS = "get_all_plan_success";
export const GET_PLAN = "get_plan";
export const GET_PLAN_SUCCESS = "get_plan_success";
export const UPDATE_PLAN = "update_plan";
export const UPDATE_PLAN_SUCCESS = "update_plan_success";
export const UPDATE_PLAN_ERROR = "update_plan_error";
export const CREATE_PLAN = "create_plan";
export const CREATE_PLAN_SUCCESS = "create_plan_success";
export const CREATE_PLAN_ERROR = "create_plan_error";
export const DELETE_PLAN = "delete_plan";
export const DELETE_PLAN_SUCCESS = "delete_plan_Success";
export const REPEAT_PLAN = "repeat_plan";
export const REPEAT_PLAN_SUCCESS = "repeat_plan_success";
export const REPEAT_PLAN_ERROR = "repeat_plan_error";

// temp
export const GET_ALL_TEMP = "get_all_temp";
export const GET_ALL_TEMP_SUCCESS = "get_all_temp_success";
export const GET_TEMP = "get_temp";
export const GET_TEMP_SUCCESS = "get_temp_success";
export const UPDATE_TEMP = "update_temp";

export const UPDATE_TEMP_SUCCESS = "update_temp_success";
export const UPDATE_TEMP_ERROR = "update_temp_error";

export const UPDATE_TEMP_CATE_SUCCESS = "update_temp_cate_success";
export const UPDATE_TEMP_CATE_ERROR = "update_temp_cate_error";

export const CREATE_TEMP = "create_temp";
export const CREATE_TEMP_SUCCESS = "create_temp_success";
export const CREATE_TEMP_ERROR = "create_temp_error";
export const CREATE_TEMP_CATE = "CREATE_TEMP_CATE";
export const CREATE_TEMP_CATE_SUCCESS = "create_temp_cate_success";
export const CREATE_TEMP_CATE_ERROR = "create_temp_cate_error";
export const DELETE_TEMP = "delete_temp";
export const DELETE_TEMP_SUCCESS = "delete_temp_Success";
export const REPEAT_TEMP = "repeat_temp";
export const REPEAT_TEMP_SUCCESS = "repeat_temp_success";
export const REPEAT_TEMP_ERROR = "repeat_temp_error";
export const GET_SEARCH_TEMPLATE_SUCCESS = 'get_search_template_success';

// customer
export const GET_CUSTOMER_COUNT = "get_customer_count";
export const GET_CUSTOMER_COUNT_SUCCESS = "get_customer_count_success";
export const GET_CUSTOMER_COUNT_BY_PLAN = "get_customer_count_by_plan";
export const GET_CUSTOMER_COUNT_BY_PLAN_SUCCESS =
  "get_customer_count_by_plan_success";

// market
export const GET_MARKET_DATA = "get_market_data";
export const GET_MARKET_DATA_SUCCESS = "get_market_data_success";
export const GET_MARKET_ALL_DATA = "get_market_all_data";
export const GET_MARKET_ALl_DATA_SUCCESS = "get_market_all_data_success";
export const CREATE_MARKET = "create_market";
export const CREATE_MARKET_SUCCESS = "create_market_success";
export const CREATE_MARKET_ERROR = "create_market_error";

// chart data
export const GET_CHART_DATA = "get_chart_data";
export const GET_CHART_DATA_SUCCESS = "get_chart_data_success";
export const GET_CHART_DAILY_DATA = "get_chart_d_data";
export const GET_CHART_DAILY_DATA_SUCCESS = "get_chart_d_data";
export const GET_CHART_MONTHLY_DATA = "get_chart_m_data";
export const GET_CHART_MONTHLY_DATA_SUCCESS = "get_chart_m_data";
export const GET_CHART_YEARLY_DATA = "get_chart_y_data";
export const GET_CHART_YEARLY_DATA_SUCCESS = "get_chart_y_data";

// analytic data
export const GET_ANAYTIC_DATA = "get_analytic_data";

// apps
export const GET_ALL_APPS = "get_all_apps";
export const GET_ALL_APPS_SUCCESS = "get_all_apps_success";
export const CREATE_APP = "create_app";
export const CREATE_APP_SUCCESS = "create_app_success";
export const CREATE_APP_ERROR = "create_app_error";
export const UPDATE_APP_SUCCESS = "update_app_success";

// controls
export const UPDATE_CONTROL_OPERATION_SUCCESS = "update_control_operation_success";

// help 
export const GET_SEGMENTS_SUCCESS = "get_segments_success";
export const GET_RECORDS_SUCCESS = "get_records_success";
export const SEGMENT_ADD_SUCCESS = "segment_add_success";
export const SEGMENT_UPDATE_SUCCESS = "segment_update_success";
export const SEGMENT_DELETE_SUCCESS = "segment_delete_success";
export const RECORD_ADD_SUCCESS = 'record_add_success';
export const RECORD_UPDATE_SUCCESS = 'record_update_success';
export const RECORD_DELETE_SUCCESS = 'record_delete_success';
export const GET_ALL_TAGS_SUCCESS = 'get_all_tags_success';
export const RECORD_ISACTIVE_CHANGE_SUCCESS = 'record_isactive_change_success';
export const RECORD_ISPREMIUM_CHANGE_SUCCESS = 'record_ispremium_change_success';

//creators
export const GET_CREATORS_SUCCESS = "get_creators_success";
export const CREATOR_DELETE_SUCCESS = "creator_delete_success";
export const CREATOR_ISACTIVE_CHANGE_SUCCESS = "creator_isactive_change_success";
export const CREATOR_UPDATE_SUCCESS = "creator_update_success";
export const CREATOR_TYPE_UPDATE_SUCCESS = "creator_type_update_success";
export const GET_CREATOR_REQUEST = "get_creator_request";
export const GET_CREATOR_SUCCESS = "get_creator_success";

// collections
export const GET_COLLECTIONS_SUCCESS = "get_collections_success";