import {
    GET_SEGMENTS_SUCCESS,
    GET_RECORDS_SUCCESS,
    SEGMENT_ADD_SUCCESS,
    SEGMENT_UPDATE_SUCCESS,
    SEGMENT_DELETE_SUCCESS,
    SHOW_MESSAGE,
    RECORD_ADD_SUCCESS,
    RECORD_UPDATE_SUCCESS,
    RECORD_DELETE_SUCCESS,
    GET_ALL_TAGS_SUCCESS,
    RECORD_ISACTIVE_CHANGE_SUCCESS,
    RECORD_ISPREMIUM_CHANGE_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    recordsLoader: true,
    records: [],
    segmentLoader: true,
    segmentList: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SEGMENTS_SUCCESS: {
            return {
                ...state,
                segmentLoader: false,
                segmentList: action.payload,
                loader: false
            }
        }
        case SEGMENT_ADD_SUCCESS: {
            return {
                ...state,
                segmentLoader: false,
                segmentList: [...state.segmentList, action.payload],
                loader: false
            }
        }
        case SEGMENT_UPDATE_SUCCESS: {
            let segmentList = state.segmentList;
            for(let i=0;i<segmentList.length;i++) {
                if(segmentList[i].id==action.payload.id) {
                    segmentList[i] = {...segmentList[i], name: action.payload.name}
                }
            }
            return {
                ...state,
                segmentLoader: false,
                segmentList: [...segmentList],
                loader: false
            }
        }
        case SEGMENT_DELETE_SUCCESS: {
            let newSegmentList = [];
            for(let i=0;i<state.segmentList.length;i++) {
                if(state.segmentList[i].id!=action.payload.id) {
                    newSegmentList.push(state.segmentList[i]);
                }
            }
            return {
                ...state,
                segmentLoader: false,
                segmentList: newSegmentList,
                loader: false
            }
        }
        case GET_ALL_TAGS_SUCCESS: {
            return {
                ...state,
                tagsLoader: false,
                allTags: action.payload,
                loader: false
            }
        }
        case GET_RECORDS_SUCCESS: {
            return {
                ...state,
                recordsLoader: false,
                records: action.payload,
                loader: false
            }
        }
        case RECORD_ADD_SUCCESS: {
            return {
                ...state,
                recordsLoader: false,
                records: [ action.payload[0], ...state.records ],
                loader: false
            }
        }
        case RECORD_UPDATE_SUCCESS: {
            let records = state.records;
            for(let i=0;i<records.length;i++) {
                if(records[i].id==action.payload.id) {
                    console.log({...records[i], ...action.payload});
                    records[i] = {...records[i], ...action.payload}
                }
            }
            return {
                ...state,
                recordsLoader: false,
                records: [...records],
                loader: false
            }
        }
        case RECORD_DELETE_SUCCESS: {
            let newRecordList = [];
            for(let i=0;i<state.records.length;i++) {
                if(state.records[i].id!=action.payload.id) {
                    newRecordList.push(state.records[i]);
                }
            }
            return {
                ...state,
                recordsLoader: false,
                records: newRecordList,
                loader: false
            }
        }
        case RECORD_ISACTIVE_CHANGE_SUCCESS: {
            let records = state.records;
            for(let i=0;i<records.length;i++) {
                if(records[i].id==action.payload.id) {
                    // console.log({...records[i], ...action.payload});
                    records[i] = {...records[i], isActive: action.payload.isActive}
                }
            }
            return {
                ...state,
                recordsLoader: false,
                records: [...records],
                loader: false
            }
        }
        case RECORD_ISPREMIUM_CHANGE_SUCCESS: {
            let records = state.records;
            for(let i=0;i<records.length;i++) {
                if(records[i].id==action.payload.id) {
                    // console.log({...records[i], ...action.payload});
                    records[i] = {...records[i], isStar: action.payload.isStar}
                }
            }
            return {
                ...state,
                recordsLoader: false,
                records: [...records],
                loader: false
            }
        }
        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                message: 'Successfully restart',
                errorType: "success",
                showMessage: true,
                loader: false,
            };
        }
        default:
            return state;
    }
}