import axios from 'axios';
import {API_URL_BASE} from '../../constants/Common';

// const axiosInstance = axios.create({
//     baseURL: '/'
// });

export default class AuthApi {
  
    static getCurrentUser() {
    return axios.get(`${API_URL_BASE}/currentuser`); 
    // return fetch(`${API_URL_BASE}/currentuser`, {
    //     method: 'get',
    //     headers: {
    //       'Cache': 'no-cache'
    //     },
    //     credentials:'same-origin'
    //   })
    }
  
    static edit(payload) {
    return axios.put(`${API_URL_BASE}/edit/payload.id`, payload); }
  
    static login(payload) {
        //axios.defaults.withCredentials = true; 
    return axios.post(`${API_URL_BASE}/login`, payload); 
    
    // return fetch(`${API_URL_BASE}/login`, {
    //     method: 'post',
    //     headers: {
    //         'Access-Control-Allow-Origin':'*',
    //       'Content-Type': 'application/json; charset=utf-8',
    //       'Cache': 'no-cache'
    //     },
    //     credentials:'omit',
    //     body: JSON.stringify(payload)
    //   })
    }
  
    static delete(payload) {
    return axios.delete(`${API_URL_BASE}/delete/${payload.id}`); }

    static logout() {
        return axios.get(`${API_URL_BASE}/logout`); 
    }

}