import {
    GET_ALL_TEMP,
    GET_ALL_TEMP_SUCCESS,
    GET_TEMP,
    GET_TEMP_SUCCESS,
    UPDATE_TEMP,
    UPDATE_TEMP_SUCCESS,
    DELETE_TEMP,
    CREATE_TEMP,
    CREATE_TEMP_SUCCESS,
    DELETE_TEMP_SUCCESS,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    REPEAT_TEMP_ERROR,
    REPEAT_TEMP_SUCCESS,
    REPEAT_TEMP,
    CREATE_TEMP_ERROR,
    CREATE_TEMP_CATE_ERROR,
    CREATE_TEMP_CATE_SUCCESS,
    UPDATE_TEMP_CATE_SUCCESS,
    CREATE_TEMP_CATE,
} from 'constants/ActionTypes';

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    createTempSuccess:false,
    createTempCateError: false,
    createTempCateSuccess: false,
    templatesList: false,
    templatesCategory: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CREATE_TEMP:{
            return{
                ...state,
                createTempSuccess: false,
                createTempError: false
            }
        }

        case CREATE_TEMP_SUCCESS:{

            let templatesList =  state.templatesList 
            templatesList.push(action.payload)

            return{
                ...state,
                isCreate:false,
                createTempSuccess:true,
                createTempError:false,
                templatesList: [...templatesList]
            }
        }

        case CREATE_TEMP_ERROR:{
            return{
                ...state,
                createTempError:action.payload
            }

        }


        case CREATE_TEMP_CATE:{
            return{
                ...state,
                createTempCateSuccess: false,
                createTempCateError: false
            }
        }

        case CREATE_TEMP_CATE_SUCCESS:{

            let templatesCategory =  state.templatesCategory 
            templatesCategory.push(action.payload)

            return{
                ...state,
                createTempCateSuccess: true,
                createTempCateError: false,
                templatesCategory: [...templatesCategory]
            }
        }

        case  UPDATE_TEMP_SUCCESS:{

            let templatesList =  state.templatesList
            const index = templatesList.findIndex(data=>data.id==action.payload.id)
            templatesList[index] = {...templatesList[index], ...action.payload}

            if(action.payload.isVisible && action.payload.isVisible==0)
                templatesList.splice(index,1)
            
            return{
                ...state,
                isCreate:false,
                createTempSuccess:true,
                createTempError:false,
                templatesList: [...templatesList]
            }

        }

        case UPDATE_TEMP_CATE_SUCCESS: {

            let templatesCategory =  state.templatesCategory 
            const ind = templatesCategory.findIndex(data=>data.id==action.payload.id)
            templatesCategory[ind] = {...templatesCategory[ind],...action.payload}

            if(action.payload.isVisible && action.payload.isVisible==0)
            templatesCategory.splice(ind,1)
       
            return{
                ...state,
                createTempCateSuccess: true,
                createTempCateError: false,
                templatesCategory: [...templatesCategory]
            }

        }

        case CREATE_TEMP_CATE_ERROR:{
            return{
                ...state,
                createTempCateError:action.payload
            }
        }
        

        case GET_ALL_TEMP: {
            return {
                ...state,
                loader: true,
            }
        }

        case GET_ALL_TEMP_SUCCESS: {
            return {
                ...state,
                loader:false,
                templatesCategory:action.payload.templatesCategory,
                templatesList: action.payload.templatesList
            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }

        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }
      
        default:
            return state;
    }
}
