import {
  GET_ALL_USER,
  GET_ALL_USER_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  UPDATE_USER_STATUS_SUCCESS,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_ERROR,
  CHECK_EMAIL,
  UPDATE_VERIFY_FIELD_SUCCESS,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_USER_CREDIT_SUCCESS,
  SHOW_CREDIT_UPDATE
} from "constants/ActionTypes";

import update from "immutability-helper/index";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  userData: null,
  isCheckEmail: false,
  checkEmailData: null,
  checkEmailError: false,
  errorType: "",
  message: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USER: {
      return {
        ...state,
        loader: true,
      };
    }

    case UPDATE_USER_STATUS_SUCCESS: {
      const index = state.userData.findIndex((o) => o.id == action.payload.id);

      if (index >= 0) {
        return update(state, {
          userData: {
            [index]: {
              isActive: { $set: action.payload.isActive == 0 ? 0 : 1 },
            },
          },
          showMessage: { $set: true },
          errorType: { $set: "success" },
          message: {
            $set:
              action.payload.isActive == 0
                ? "User has been marked unactive successfully"
                : "User has been marked active successfully",
          },
        });
      }

      return state;
      break;
    }

    case UPDATE_VERIFY_FIELD_SUCCESS: {
      const index = state.userData.findIndex((o) => o.id == action.payload.id);

      if (index >= 0) {
        return update(state, {
          userData: {
            [index]: {
              isEmailVerified: { $set: action.payload.isEmailVerified == 0 ? 0 : 1 },
            },
          },
          showMessage: { $set: true },
          errorType: { $set: "success" },
          message: {
            $set:
              action.payload.isEmailVerified == 0
                ? "User has been unverified successfully"
                : "User has been verified successfully",
          },
        });
      }

      return state;
      break;
    }

    case DELETE_USER_SUCCESS: {
      const index = state.userData.findIndex(
        (o) => o.id == action.payload.userId
      );
      if (index >= 0)
        return update(state, {
          userData: {
            [index]: { isDelete: { $set: action.payload.status == 0 ? 0 : 1 } },
          },
        });
      return state;
      break;
    }

    case UPDATE_USER_EMAIL_SUCCESS: {
      const index = state.userData.findIndex((o) => o.id == action.payload.id);

      if (index >= 0) {
        return update(state, {
          userData: {
            [index]: {
              email: { $set: action.payload.email },
            },
          },
          showMessage: { $set: true },
          errorType: { $set: "success" },
          message: {
            $set: "Email address has been changed successfully"
          },
        });
      }

      return state;
      break;
    }

    case UPDATE_USER_CREDIT_SUCCESS: {
      const index = state.userData.findIndex((o) => o.id == action.payload.id);
      if (index >= 0 ) {
        return update(state, {
          userData: {
            [index]: {
              credit: { $set: action.payload.credit },
            },
          },
          showMessage: { $set: true },
          errorType: { $set: "success" },
          message: {
            $set: "Startup credit added successfully"
          },
        });
      }

      return state;
      break;
    }

    case SHOW_CREDIT_UPDATE :{
      return update(state, {
        showMessage: { $set: true },
        errorType: { $set: "error" },
        message: {
          $set: action.payload
        },
      } )
    }
    case GET_ALL_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        userData: action.payload,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
      };
    }

    default:
      return state;
  }
};
