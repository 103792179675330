import {
    GET_ALL_APPS_SUCCESS,
    CREATE_APP,
    CREATE_APP_ERROR,
    CREATE_APP_SUCCESS,
    UPDATE_APP_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    appsList: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_APPS_SUCCESS: {
            console.log({
                ...state,
                appsList: [...action.payload]
            })
            return {
                ...state,
                appsList: [...action.payload]
            }
        }

        case CREATE_APP:{
            return{
                ...state,
                createAppSuccess: false,
                createAppError: false
            }
        }

        case CREATE_APP_ERROR:{
            return{
                ...state,
                createAppError: action.payload
            }
        }

        case CREATE_APP_SUCCESS:{

            let appsList =  state.appsList 
            appsList.push(action.payload)

            return{
                ...state,
                createAppSuccess: true,
                createAppError: false,
                appsList: [...appsList]
            }
        }
      
        case  UPDATE_APP_SUCCESS:{

            let appsList =  state.appsList;
            const index = appsList.findIndex(app=>app.id==action.payload.id);
            appsList[index] = {...appsList[index], ...action.payload};
            
            return {
                ...state,
                createAppSuccess: true,
                createAppError: false,
                appsList: [...appsList]
            };

        }

        default:
            return state;
    }
}
