import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SET_CURRENT_USER,
  SET_CURRENT_USER_ERROR,
  SIGNIN_PROGRESS,
} from "constants/ActionTypes";
import { API_URL_BASE, SERVER_ERROR_MESSAGE } from "../constants/Common";
import axios from "axios";

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user,
  };
};

export const userSignIn = (user) => {
  console.log("HOST ---> " + API_URL_BASE);

  return async (dispatch) => {
    try {
      dispatch({ type: SIGNIN_PROGRESS, payload: "signin_call" });
      const response = await axios.post(`${API_URL_BASE}/login`, user);
      const responseData = response ? response.data : {};

      console.log("--- LOGIN ---");
      //console.log(response);

      if (responseData.success) {
        showAuthMessage(responseData.message);
      } else if (responseData.message) {
        const responseUser = await axios.get(`${API_URL_BASE}/currentuser`);

        if (responseUser.data.success) {
          dispatch({ type: SHOW_MESSAGE, message: responseUser.data.message });
        } else if (responseUser.data.data) {
          let permissionsArray = responseUser.data.data.permissions.split("'");
          let newPermissionArray = [];

          permissionsArray.map((per) => {
            if (
              !per.includes("[") &&
              !per.includes("]") &&
              !per.includes(",")
            ) {
              newPermissionArray.push(per);
            }
          });

          let data = {
            ...responseUser.data.data,
            permissions: newPermissionArray,
          };
          // localStorage.setItem('authUser', data);
          dispatch({ type: SET_CURRENT_USER, payload: data });
          dispatch({ type: SIGNIN_PROGRESS, payload: "signin_call_finished" });
        } else if (responseUser.data.success) {
          dispatch({ type: SHOW_MESSAGE, payload: responseUser.data.message });
        }
      }
    } catch (error) {
      console.log("ERROR CONSOLE");
      console.log(error);

      if (error.response && error.response.data) {
        if (error.response.data.error) {
          console.log(error.response.data.error);
          dispatch({ type: SHOW_MESSAGE, payload: error.response.data.error });
        }
      } else {
        dispatch({ type: SHOW_MESSAGE, payload: "Server is not responding" });
      }
    }
  };
};

export const getCurrentUser = () => {
  return async (dispatch) => {
    try {
      const responseUser = await axios.get(`${API_URL_BASE}/currentuser`,{withCredentials: true});
      if (responseUser.data.success) {
        dispatch({ type: SHOW_MESSAGE, message: responseUser.data.message });
      } else if (responseUser.data.data) {
        let permissionsArray = responseUser.data.data.permissions.split("'");
        let newPermissionArray = [];

        permissionsArray.map((per) => {
          if (!per.includes("[") && !per.includes("]") && !per.includes(",")) {
            newPermissionArray.push(per);
          }
        });

        let data = {
          ...responseUser.data.data,
          permissions: newPermissionArray,
        };
        // localStorage.setItem('authUser', data);
        dispatch({ type: SET_CURRENT_USER, payload: data });
      } else if (!responseUser.data.success) {
        dispatch({ type: SHOW_MESSAGE, payload: responseUser.data.message });
      }
    } catch (error) {
      dispatch({ type: SET_CURRENT_USER_ERROR, payload: null });
    }
  };
};

export const userSignOut = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL_BASE}/logout`);
      if (response.data) {
        dispatch({ type: SHOW_MESSAGE, payload: response.data.message });
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: SHOW_MESSAGE, payload: SERVER_ERROR_MESSAGE });
    }
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  };
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
