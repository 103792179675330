const returnEnvironmentAPIBase = () => {
  const hostname = window && window.location && window.location.hostname;
  let API_URI_BASE = "";

  if (hostname === "localhost") {
    // API_URI_BASE = "http://192.168.29.29:3010/api";
    API_URI_BASE = "https://admin.collectby.com/api";
  } else {
    API_URI_BASE = "https://admin.collectby.com/api";
  }
  return API_URI_BASE;
};

export default returnEnvironmentAPIBase;
