import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  getCurrentUser,
} from "actions/Auth";
import showNotificationPane from "../util/NotificationMngt";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser) {
      this.props.history.push("/");
    }
  }

  displayNotification(type, message) {
    this.NotificationManager = showNotificationPane(type, message);
  }

  render() {
    const { email, password } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    console.log("IS SIGNING");
    console.log(this.props.signInProgress);

    if (showMessage === true) {
      this.displayNotification("error", alertMessage);
    }

    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Jambo">
              <img
                style={{ filter: "brightness(0) invert(1)" }}
                src={require("assets/images/logo.png")}
                alt="jambo"
                title="jambo"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Stackby Admin Panel</h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userSignIn({ email, password });
                      }}
                      variant="contained"
                      color="primary"
                      style={{ paddingTop: "3px", paddingBottom: "3px" }}
                    >
                      {this.props.signInProgress === true ? (
                        <div>
                          <table>
                            <tr>
                              <td>SIGNING IN &nbsp;&nbsp;</td>
                              <td
                                style={{
                                  paddingTop: "6px",
                                }}
                              >
                                <CircularProgress size={16} color="inherit" />
                              </td>
                            </tr>
                          </table>
                        </div>
                      ) : (
                        <div>
                          <table>
                            <tr>
                              <td
                                style={{
                                  paddingTop: "5px",
                                }}
                                colSpan="2"
                              >
                                SIGN IN
                              </td>
                            </tr>
                          </table>
                        </div>
                      )}
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}

        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser, signInProgress } = auth;
  return { loader, alertMessage, showMessage, authUser, signInProgress };
};
const dispatchToProps = (dispatch) => {
  return {
    userSignIn: (user) => dispatch(userSignIn(user)),
    hideMessage: () => dispatch(hideMessage()),
    showAuthLoader: () => dispatch(showAuthLoader()),
    getCurrentUser: () => dispatch(getCurrentUser()),
  };
};

export default connect(mapStateToProps, dispatchToProps)(SignIn);
