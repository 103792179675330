import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const showNotificationPane = (type, message) => {
  switch (type) {
    case "info":
      return NotificationManager.info(message);
      break;
    case "success":
      return NotificationManager.success(message);
      break;
    case "warning":
      return NotificationManager.warning(message);
      break;
    case "error":
      return NotificationManager.error(message);
      break;
  }
};

export default showNotificationPane;
