import {
    GET_ALL_PLAN,
    GET_ALL_PLAN_SUCCESS,
    GET_PLAN,
    GET_PLAN_SUCCESS,
    UPDATE_PLAN,
    UPDATE_PLAN_SUCCESS,
    DELETE_PLAN,
    CREATE_PLAN,
    CREATE_PLAN_SUCCESS,
    DELETE_PLAN_SUCCESS,
    SHOW_MESSAGE,
    HIDE_MESSAGE,
    REPEAT_PLAN_ERROR,
    REPEAT_PLAN_SUCCESS,
    REPEAT_PLAN,
    CREATE_PLAN_ERROR
} from 'constants/ActionTypes';

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    planData:false,
    isRepeatLoading:false,
    repeatError:null,
    createPlanSuccess:false,
    createPlanError:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CREATE_PLAN_SUCCESS:{

            let planData = state.planData 
            planData.push(action.payload)
            return{
                ...state,
                createPlanSuccess:true,
                createPlanError:false,
                planData: planData
            }
        }

        case CREATE_PLAN_ERROR:{
            return{
                ...state,
                createPlanError:action.payload
            }

        }

        case REPEAT_PLAN:{
            return{
                ...state,
                isRepeatLoading:true,
                repeatError:null,
            }
        }
     
        case REPEAT_PLAN_ERROR:{
            return{
                ...state,
                isRepeatLoading:false,
                repeatError:action.payload

            }

        }

        case REPEAT_PLAN_SUCCESS:{
            return{
                ...state,
                isRepeatLoading:false
            }

        }



        case GET_ALL_PLAN: {
            return {
                ...state,
                loader: true,
            }
        }

        case GET_ALL_PLAN_SUCCESS: {
            return {
                ...state,
                loader:false,
                planData:action.payload
            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }

        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }
      
        default:
            return state;
    }
}
