import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/home">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Home </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/users">
              <i className="zmdi zmdi-account-box zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.users" />
              </span>
            </NavLink>
          </li>

          {/* <li className="menu no-arrow">
                <NavLink to="/app/market">
                    <i className="zmdi zmdi-book-image zmdi-hc-fw"/>
                    <span className="nav-text"><IntlMessages id="sidebar.market"/></span>
                </NavLink>
            </li> */}

          {/* <li className="menu no-arrow">
                <NavLink to="/app/customer">
                    <i className="zmdi zmdi-account-box zmdi-hc-fw"/>
                    <span className="nav-text"><IntlMessages id="sidebar.customers"/></span>
                </NavLink>
            </li> */}

          <li className="menu no-arrow">
            <NavLink to="/app/plans">
              <i className="zmdi zmdi-money zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.plans" />
              </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/workspace">
              <i className="zmdi zmdi-daydream-setting zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="Workspace" />
              </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/campaign/workspace">
              <i className="zmdi zmdi-daydream-setting zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="Campaign Workspace" />
              </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/templates">
              <i className="zmdi zmdi-format-subject zmdi-hc-fw" />
              <span className="nav-text">templates</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/manageusers">
              <i className="zmdi zmdi-accounts-list zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.manageUsers" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/maker-community">
              <i className="zmdi zmdi-balance zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.makerCommunity" />
              </span>
            </NavLink>
          </li>

          <li className="menu">
            <NavLink to="/app/manageaes">
              <i className="zmdi zmdi-collection-item zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.manageAesTemplates" />
              </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/inapp">
              <i className="zmdi zmdi-notifications zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.inappNotification" />
              </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/apps">
              <i className="zmdi zmdi-shopping-basket zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.apps" />
              </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/controls">
              <i className="zmdi zmdi-refresh-alt zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.controls" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/help">
              <i className="zmdi zmdi-help zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.help" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/creator">
              <i className="zmdi zmdi-assignment"></i>
              <span className="nav-text">
                <IntlMessages id="sidebar.creator" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/collections">
              <i className="zmdi zmdi-collection-item-1"></i>
              <span className="nav-text">
                <IntlMessages id="sidebar.collections" />
              </span>
            </NavLink>
          </li>
          {/* <li className="menu no-arrow">
                <NavLink to="/app/mychart">
                    <i className="zmdi zmdi-trending-up zmdi-hc-fw"/>
                    <span className="nav-text"><IntlMessages id="sidebar.chart"/></span>
                </NavLink>
            </li> */}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
