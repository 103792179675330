import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  GET_CURRENT_USER,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
} from "actions/Auth";

import AuthApi from "./api/Auth";
import { SERVER_ERROR_MESSAGE } from "constants/Common";

function* signInUserWithEmailPassword({ payload }) {
  try {
    const response = yield call(AuthApi.login, payload);
    console.log(response);
    const responseData = response ? response.data : {};

    console.log(responseData);

    if (responseData.success) {
      yield put(showAuthMessage(responseData.message));
    } else if (responseData.message) {
      const responseUser = yield call(AuthApi.getCurrentUser);

      if (responseUser.data.success) {
        yield put(showAuthMessage(responseUser.data.message));
      } else if (responseUser.data.data) {
        console.log(responseUser.data.data);

        let permissionsArray = responseUser.data.data.permissions.split("'");
        let newPermissionArray = [];

        permissionsArray.map((per) => {
          if (!per.includes("[") && !per.includes("]") && !per.includes(",")) {
            newPermissionArray.push(per);
          }
        });

        let data = {
          ...responseUser.data.data,
          permissions: newPermissionArray,
        };
        // localStorage.setItem('authUser', data);
        yield put(userSignInSuccess(data));
      } else if (responseUser.data.success) {
        yield put(showAuthMessage(responseUser.data.message));
      }
    }
  } catch (error) {
    console.log(error.response);

    if (error.response && error.response.data) {
      if (error.response.data.error) {
        console.log(error.response.data.error);
        yield put(showAuthMessage(error.response.data.error));
      }
    } else {
      yield put(showAuthMessage(SERVER_ERROR_MESSAGE));
    }
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(AuthApi.logout);
    console.log(signOutUser.data);
    if (signOutUser.data.message) {
      yield put(userSignOutSuccess());
      // localStorage.removeItem('authUser');
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* getCurrentUserApi() {
  try {
    const response = yield call(AuthApi.getCurrentUser);

    const responseData = response ? response.data : {};

    if (responseData.data) {
      let permissionsArray = responseData.data.permissions.split("'");
      let newPermissionArray = [];

      permissionsArray.map((per) => {
        if (!per.includes("[") && !per.includes("]") && !per.includes(",")) {
          newPermissionArray.push(per);
        }
      });

      let data = { ...responseData.data, permissions: newPermissionArray };
      yield put(userSignInSuccess(data));
    } else if (responseData.success) {
      yield put(showAuthMessage(responseData.message));
    }
  } catch (error) {}
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* getLoginUser() {
  yield takeEvery(GET_CURRENT_USER, getCurrentUserApi);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser), fork(getLoginUser)]);
}
