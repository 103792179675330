import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Role from './Role';
import User from './User';
import Plan from './Plans';
import Templates from './Templates';
import Analytics from './Analytics';
import Apps from './Apps';
import Controls from './Controls';
import Help from './Help';
import Creator from './Creator';
import Collection from './Collection';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  role: Role,
  user: User,
  plans: Plan,
  templates: Templates,
  analytics: Analytics,
  apps :Apps,
  controls: Controls,
  help: Help,
  creator: Creator,
  collection: Collection
});
