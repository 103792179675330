import {
    GET_ALL_ROLE,
    GET_ALL_ROLE_SUCCESS,
    CREATE_ROLE,
    CREATE_ROLE_SUCCESS,
    GET_ROLE,
    GET_ROLE_SUCCESS,
    UPDATE_ROLE,
    UPDATE_ROLE_SUCCESS,
    DELETE_ROLE,
    DELETE_ROLE_SUCCESS,
    SHOW_MESSAGE,
    HIDE_MESSAGE
} from 'constants/ActionTypes';

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    roleData:null,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CREATE_ROLE:{
            return {
                ...state,
                isCreate: true,
            }
        }

        case CREATE_ROLE_SUCCESS:{
            return{
                ...state,
                isCreate:false,
            }
        }
     
        case GET_ALL_ROLE: {
            return {
                ...state,
                loader: true,
            }
        }

        case GET_ALL_ROLE_SUCCESS: {
            return {
                ...state,
                loader:false,
                roleData:action.payload
            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }

        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }
      
        default:
            return state;
    }
}
