import {
    GET_COLLECTIONS_SUCCESS,
    CREATOR_DELETE_SUCCESS,
    CREATOR_ISACTIVE_CHANGE_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
    collectionsLoader: true,
    collections: [],
    showMessage: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COLLECTIONS_SUCCESS: {
            return {
                ...state,
                collectionsLoader: false,
                collections: action.payload
            }
        }
        default:
            return state;
    }
}