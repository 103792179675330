import {
    UPDATE_CONTROL_OPERATION_SUCCESS,
    SHOW_MESSAGE
} from 'constants/ActionTypes';

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_CONTROL_OPERATION_SUCCESS: {
            return {
                ...state,
                alertMessage: 'aasdf',
                showMessage: true,
                loader: false
            }
        }
        
        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                message: 'Successfully restart',
                errorType: "success",
                showMessage: true,
                loader: false,
            };
        }
        default:
            return state;
    }
}