import {
    GET_ANAYTIC_DATA
  } from "../constants/ActionTypes";
  
  import update from "immutability-helper/index";
  
  const INIT_STATE = {
    loader: false,
    alertMessage: "",
    showMessage: false,
    topFiveUsedTempate: [],
    topFiveUsedApi: [],
    totalStackTillDate: 0,
    totalUserTillDate: 0,
    totalApiConnectorCount: 0,
    errorType: "",
    message: "",
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ANAYTIC_DATA: {
        return {
          ...state,
          loader: true,
          topFiveUsedTempate: action.payload.top5Template,
          topFiveUsedApi: action.payload.top5Api,
          totalUserTillDate: action.payload.totalUser,
          totalStacksCreatedTillDate: action.payload.totalStack,
          totalApiConnectorCount: action.payload.totalApiConnectorCount,
          totalAppCreatedCount: action.payload.totalApp
        };
      }
  
      default:
        return state;
    }
  };
  