import {
  HIDE_MESSAGE,
  INIT_URL,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SET_CURRENT_USER,
  SET_CURRENT_USER_ERROR,
  SIGNIN_PROGRESS,
} from "../constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  authUser: false,
  signInProgress: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_USER: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SET_CURRENT_USER_ERROR: {
      return {
        ...state,
        loader: false,
        authUser: false,
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_PROGRESS: {
      console.log("SIGNING PROGRESS");
      console.log(action.payload);
      return {
        ...state,
        signInProgress: action.payload === "signin_call" ? true : false,
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: false,
        initURL: "/app/home",
        loader: false,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
        signInProgress: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
        signInProgress: false,
      };
    }
    default:
      return state;
  }
};
